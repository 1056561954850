import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { api_url } from "@/constants/base_url";
import { useMutation } from "@tanstack/react-query";
import { useDropzone } from "react-dropzone";
import getImageUrl from "@shared/Helpers/image/getImageUrl";
import { queryClient } from "@config/queryClient.config";
import { ClipLoader } from "react-spinners";

export default function EditHomeWork({ homeWork }) {
  const [open, setOpen] = useState(false);

  const methods = useForm();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    getValues,
    control,
    setValue,
    setError,
    clearErrors,
  } = methods;

  const [questionsArray, setQuestionsArray] = useState([]);
  const [questionsLength, setQuestionsLength] = useState(0);

  useEffect(() => {
    if (homeWork?.questions?.length !== 0) {
      setQuestionsLength(homeWork?.questions?.length);
      setQuestionsArray(Array(homeWork?.questions?.length).fill(""));
      setValue("ends_at", dayjs(homeWork?.ends_at));
      setValue("starts_at", dayjs(homeWork?.starts_at));
      setValue("title", homeWork?.title);
      setValue("exam_time", homeWork?.exam_time);

      let index1 = 0;
      for (let question of homeWork?.questions) {
        const correctAnswer = question?.answers.findIndex(
          (answer) => answer?.isCorrect
        );

        setValue(`questions.${index1}.correctAnswer`, correctAnswer);
        setValue(`questions.${index1}.title`, question?.title);

        // setValue(`questions.${index1}.image`, question?.image);
        for (let i = 0; i < homeWork?.questions?.length; i++) {
          const question = homeWork?.questions[i];
          if (
            question?.image?.length > 0 &&
            question?.image[0] instanceof File
          ) {
            setValue(`questions[${i}][image]`, question?.image[0]);
          }
        }

        setValue(`questions.${index1}.grade`, String(question?.grade));

        let index2 = 0;
        for (let answer of question?.answers) {
          setValue(
            `questions.${index1}.answers.${index2}.title`,
            answer?.title
          );

          index2++;
        }
        index1++;
      }
    }
  }, [homeWork, setValue, setQuestionsArray, setQuestionsLength]);

  const timerRef = useRef();

  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) =>
      axios.post(`${api_url}homeWork/updateHomeWork`, data, {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["module homework"]);

      notifySuccess("تم تعديل الواجب بنجاح");
    },
    onError: (err) => {
      console.log(err);
      notifyError("حدث خطأ ما, الرجاء المحاولة لاحقا");
    },
    onSettled: (data, error) => {
      setOpen(false);
    },
  });

  console.log("homew", homeWork);

  function submit(data) {
    const reqFormData = new FormData();

    reqFormData.append("homework_id", homeWork.id);
    reqFormData.append("title", data?.title);
    clearErrors();
    reqFormData.append("lesson_module_id", homeWork?.lesson_modules_id);

    for (let i = 0; i < data?.questions?.length; i++) {
      const question = data?.questions[i];

      if (question?.title?.length > 0) {
        reqFormData.append(`questions[${i}][title]`, question?.title);
      }

      reqFormData.append(`questions[${i}][grade]`, question?.grade);

      // if (question?.image?.length > 0) {
      //   reqFormData.append(`questions[${i}][image]`, question?.image[0]);
      // }

      if (question?.image?.length > 0 && question?.image[0] instanceof File) {
        reqFormData.append(`questions[${i}][image]`, question?.image[0]);
      } else {
        try {
          reqFormData.append(
            `questions[${i}][image]`,
            homeWork?.question[i]?.image
          );
        } catch {}
      }

      for (let j = 0; j < question?.answers?.length; j++) {
        const answer = question?.answers[j];

        reqFormData.append(
          `questions[${i}][answers][${j}][title]`,
          answer?.title
        );
        reqFormData.append(
          `questions[${i}][answers][${j}][isCorrect]`,
          Number(question?.correctAnswer) === j ? 1 : 0
        );
      }
    }
    console.log(questionsArray, "questionArray");
    console.log(data, "reqFormData");
    mutate(reqFormData);
    console.log("watchingsubmit", watch());
  }

  return (
    <>
      <IconButton
        sx={{
          bgcolor: "#01c46e",
          "&:hover": { bgcolor: "#01c46e" },
          width: 28,
          height: 28,
        }}
        onClick={() => setOpen(true)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle sx={{ borderTop: "#000 5px solid" }}>
          <Button onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>
        </DialogTitle>

        <DialogContent sx={{}}>
          <FormProvider {...methods}>
            {isLoading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ClipLoader />
              </div>
            ) : (
              <form onSubmit={handleSubmit(submit)}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "30px",
                    flexDirection: "column",
                    justifyContent: "center",
                    mb: "40px",
                  }}
                >
                  <Box style={{ width: "55%" }}>
                    <TextField
                      inputProps={{
                        style: {
                          // color: "black",
                          WebkitBoxShadow: "0 0 0 1000px white inset",
                          WebkitTextFillColor: "#2a2a2a",
                        },
                      }}
                      {...register("title", {
                        required: "اسم الواجب مطلوب",
                      })}
                      registername={"title"}
                      defaultValue={homeWork?.title}
                      error={errors?.title}
                      helperText={errors?.title?.message}
                      placeholder="ادخل اسم الواجب"
                      label="اسم الواجب"
                      size="small"
                      fullWidth
                      sx={{
                        mb: 3,
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#000",
                        },
                        "& label": {
                          left: "unset",
                          right: "1.75rem",
                          transformOrigin: "right",
                          fontSize: "0.8rem",
                          color: "#000",
                        },
                        "& legend": {
                          textAlign: "right",
                          fontSize: "0.6rem",
                        },
                        direction: "rtl",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  {questionsArray?.map((item, index) => (
                    <Question
                      key={index}
                      index={index}
                      questionData={homeWork?.questions[index]}
                      questionsLength={questionsLength}
                    />
                  ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.9rem",
                  }}
                >
                  <Box
                    sx={{
                      my: "30px",
                    }}
                  >
                    <ColorButton
                      variant="contained"
                      sx={{ bgcolor: "#000", marginLeft: "20px" }}
                      onClick={() => {
                        if (questionsArray?.length >= 3) return;

                        setQuestionsArray([
                          ...questionsArray,
                          <Question
                            key={Math.random()}
                            index={Math.random()}
                            questionsLength={questionsLength}
                          />,
                        ]);
                      }}
                    >
                      اضف سؤال
                    </ColorButton>
                  </Box>
                  <Box
                    sx={{
                      my: "30px",
                    }}
                  >
                    <ColorButton
                      variant="contained"
                      sx={{ bgcolor: "#000", marginLeft: "20px" }}
                      onClick={() => {
                        // if (questionsArray.length <= 1) return;

                        // setQuestionsArray(questionsArray.slice(0, -1));
                        // homeWork?.questions.slice(0, -1);
                        clearErrors();
                        if (questionsArray.length <= 1) return;
                        const updatedQuestionsArray = questionsArray.slice(
                          0,
                          -1
                        );
                        setQuestionsArray(updatedQuestionsArray);
                        const updatedQuestions = homeWork?.questions.slice(
                          0,
                          -1
                        );
                        const updatedHomeWork = {
                          ...homeWork,
                          questions: updatedQuestions,
                        };
                        setValue("questions", updatedQuestions);
                        clearErrors();
                      }}
                    >
                      احذف سؤال
                    </ColorButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    my: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25%",
                    marginX: "auto",
                  }}
                >
                  <ColorButton
                    disabled={
                      isLoading ||
                      (Object.keys(errors).length !== 0 &&
                        Object.keys(errors)[0] !== "starts_at")
                    }
                    type="submit"
                    variant="contained"
                    sx={{ bgcolor: "#000", marginLeft: "20px" }}
                  >
                    {isLoading ? (
                      <ClipLoader color="#000" size={20} />
                    ) : (
                      "تعديل الواجب"
                    )}
                  </ColorButton>
                </Box>
              </form>
            )}
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Question({ index, questionData, questionsLength }) {
  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useFormContext();
  const [imageSrc, setImageSrc] = useState({
    url: getImageUrl(`/${questionData?.image}`),
  });
  console.log(questionData, "questionData");
  const [answersArray, setAnswersArray] = useState(
    Array(questionData?.answers?.length || 2).fill("")
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFile) => {
      setValue(`questions.${index}.image`, acceptedFile);
      setImageSrc({
        url: URL.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
    },
  });
  console.log("watching from q", watch());

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: -10,
          }}
        >
          <div
            style={{
              width: "250px",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
            {...getRootProps()}
          >
            <img
              src={
                imageSrc?.url.endsWith("null")
                  ? "https://www.svgrepo.com/show/508699/landscape-placeholder.svg"
                  : imageSrc?.url
              }
              style={{ width: "100%", cursor: "pointer", objectFit: "cover" }}
              alt=""
            />
          </div>
          <input {...getInputProps()} />
          {errors?.questions?.[index]?.image && (
            <FormHelperText sx={{ color: "red" }}>
              {errors?.questions?.[index]?.image?.message}
            </FormHelperText>
          )}
        </Box>
        <Box>
          <Box sx={{ width: "135%" }}>
            <TextField
              inputProps={{
                style: {
                  // color: "black",
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                  WebkitTextFillColor: "#2a2a2a",
                },
              }}
              placeholder="ادخل السؤال  "
              label="ادخل السؤال "
              size="small"
              fullWidth
              defaultValue={questionData?.title}
              {...register(`questions.${index}.title`, {
                required: "ادخل نص السؤال",
              })}
              error={errors?.questions?.[index]?.title}
              helperText={errors?.questions?.[index]?.title?.message}
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000",
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: "#000",
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
            />
          </Box>
          {index >= questionsLength && (
            <Box sx={{ mt: "20px", width: "135%" }}>
              <TextField
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  style: {
                    // color: "black",
                    WebkitBoxShadow: "0 0 0 1000px white inset",
                    WebkitTextFillColor: "#2a2a2a",
                  },
                }}
                placeholder="ادخل درجة السؤال  "
                label="ادخل درجة السؤال "
                size="small"
                fullWidth
                defaultValue={questionData?.grade}
                {...register(`questions.${index}.grade`, {
                  required: "درجة السؤال مطلوبة",
                })}
                error={errors?.questions?.[index]?.grade}
                helperText={errors?.questions?.[index]?.grade?.message}
                pattern="[0-9]+"
                // disabled
                sx={{
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#000",
                  },
                  "& label": {
                    left: "unset",
                    right: "1.75rem",
                    transformOrigin: "right",
                    fontSize: "0.8rem",
                    color: "#000",
                  },
                  "& legend": {
                    textAlign: "right",
                    fontSize: "0.6rem",
                  },
                  direction: "rtl",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Controller
        rules={{ required: "اختر الاجابة الصحيحة" }}
        control={control}
        name={`questions.${index}.correctAnswer`}
        render={({ field }) => (
          <>
            <RadioGroup {...field}>
              <Box>
                {answersArray.map((_, index2) => (
                  <Answer
                    key={"answer" + index}
                    index={index}
                    index2={index2}
                    questionsLength={questionsLength}
                  />
                ))}
              </Box>
            </RadioGroup>
            <FormHelperText sx={{ color: "red", mt: 2, fontSize: 14 }}>
              {errors?.questions?.[index]?.correctAnswer?.message}
            </FormHelperText>
          </>
        )}
      />

      {index >= questionsLength && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ my: "30px" }}>
            <ColorButton
              variant="contained"
              sx={{ bgcolor: "#000", marginLeft: "20px" }}
              onClick={() => {
                if (answersArray?.length >= 4) return;
                setAnswersArray([
                  ...answersArray,
                  <Answer
                    key={"answer" + index}
                    index={index}
                    index2={answersArray?.length}
                    questionsLength={questionsLength}
                  />,
                ]);
              }}
            >
              اضف خيار
            </ColorButton>
          </Box>
          <Box sx={{ my: "30px" }}>
            <ColorButton
              variant="contained"
              sx={{ bgcolor: "#000", marginLeft: "20px" }}
              onClick={() => {
                if (answersArray.length <= 2) return;
                setAnswersArray(answersArray.slice(0, -1));
              }}
            >
              احذف خيار
            </ColorButton>
          </Box>
        </Box>
      )}
    </>
  );
}

function Answer({ index, index2, questionsLength }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mt: "20px",
          // flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "80%", mr: "20px" }}>
          <Box sx={{}}>
            <TextField
              inputProps={{
                style: {
                  // color: "black",
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                  WebkitTextFillColor: "#2a2a2a",
                },
              }}
              {...register(`questions.${index}.answers.${index2}.title`, {
                required: "نص الخيار مطلوب",
              })}
              error={errors?.questions?.[index]?.title?.[index2]?.title}
              helperText={
                errors?.questions?.[index]?.title?.[index2]?.title?.message
              }
              size="small"
              fullWidth
              placeholder="ادخل الخيار"
              label="خيار"
              type="text"
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000",
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: "#000",
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                "& input::placeholder": {
                  textAlign: "right",
                },
                direction: "ltr",
              }}
              InputProps={{
                style: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        {/* {index >= questionsLength && ( */}
        <FormControlLabel
          sx={{
            // mb: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // mr: -3,
          }}
          value={index2}
          control={<Radio />}
        />
        {/* )}{" "} */}
      </Box>
    </>
  );
}
