import {
  Box,
  FormHelperText,
  ThemeProvider,
  TextField,
  InputAdornment,
  RadioGroup,
  createTheme,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm, useFormContext } from "react-hook-form";

export function Question({ index, questionData }) {
  const [imageSrc, setImageSrc] = useState();
  const {
    register,
    formState,
    setValue,
    getValues,
    control,
    watch,
    clearErrors,
  } = useFormContext();

  const [answersArray, setAnswersArray] = useState([
    <Answer key={"answer" + index} index={index} index2={Math.random()} />,
    <Answer
      key={"answer" + (index + 1)}
      index={index}
      index2={Math.random()}
    />,
  ]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFile) => {
      // console.log(getValues())
      clearErrors(`questions.${index}.title`);
      setValue(`questions.${index}.image`, acceptedFile);
      setImageSrc({
        url: URL.createObjectURL(acceptedFile[0]),
        file: acceptedFile[0],
      });
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: -10,
          }}
        >
          <div
            style={{
              width: "250px",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
            {...getRootProps()}
          >
            <img
              src={
                imageSrc?.url ||
                "https://www.svgrepo.com/show/508699/landscape-placeholder.svg"
              }
              style={{ width: "100%", cursor: "pointer", objectFit: "cover" }}
              alt=""
            />
          </div>
          <input {...getInputProps()} />
          {formState?.errors?.questions?.[index]?.image && (
            <FormHelperText sx={{ color: "red" }}>
              {formState?.errors?.questions?.[index]?.image?.message}
            </FormHelperText>
          )}
        </Box>
        <Box>
          <Box sx={{ width: "135%" }}>
            <TextField
              inputProps={{
                style: {
                  // color: "black",
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                  WebkitTextFillColor: "#2a2a2a",
                },
              }}
              placeholder="ادخل السؤال  "
              label="ادخل السؤال "
              size="small"
              fullWidth
              {...register(`questions.${index}.title`, {
                validate: (value, { questions }) => {
                  const hasImage = getValues().questions[index]?.image?.length; // Check if image property exists
                  const isTitleEmpty = !value.trim(); // Check if title is empty

                  if (!hasImage && isTitleEmpty) {
                    return "يجب ادخال نص السؤال في حالة عدم وضع صورة"; // Show error message if title is empty and image is absent
                  }

                  return true; // Validation passed
                },
              })}
              error={formState?.errors?.questions?.[index]?.title}
              helperText={formState?.errors?.questions?.[index]?.title?.message}
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000",
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: "#000",
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ mt: "20px", width: "135%" }}>
            <TextField
              inputProps={{
                style: {
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                  WebkitTextFillColor: "#2a2a2a",
                },
              }}
              placeholder="ادخل درجة السؤال  "
              label="ادخل درجة السؤال "
              size="small"
              type="number"
              fullWidth
              {...register(`questions.${index}.grade`, {
                required: "درجة السؤال مطلوبة",
                validate: (value) =>
                  value > 0 || "درجة السؤال يجب ان تكون اكبر من 0",
              })}
              error={formState?.errors?.questions?.[index]?.grade}
              helperText={formState?.errors?.questions?.[index]?.grade?.message}
              // pattern="\d*"

              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000",
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: "#000",
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end"></InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Controller
        rules={{ required: "اختر الاجابة الصحيحة" }}
        control={control}
        name={`questions.${index}.correctAnswer`}
        render={({ field }) => (
          <>
            <RadioGroup {...field}>
              <Box>
                {answersArray.map((_, index2) => (
                  <Answer
                    key={"answer" + index2}
                    index2={index2}
                    index={index}
                  />
                ))}
              </Box>
            </RadioGroup>
            <FormHelperText sx={{ color: "red", mt: 2, fontSize: 14 }}>
              {formState?.errors?.questions?.[index]?.correctAnswer?.message}
            </FormHelperText>
          </>
        )}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Box sx={{ my: "30px" }}>
          <ColorButton
            variant="contained"
            sx={{ bgcolor: "#000", marginLeft: "20px" }}
            onClick={() => {
              if (answersArray.length >= 4) return;
              setAnswersArray([
                ...answersArray,
                <Answer
                  key={"answer" + index}
                  index={index}
                  index2={answersArray.length}
                />,
              ]);
            }}
          >
            اضف خيار
          </ColorButton>
        </Box>
        <Box sx={{ my: "30px" }}>
          <ColorButton
            variant="contained"
            sx={{ bgcolor: "#000", marginLeft: "20px" }}
            onClick={() => {
              if (answersArray.length <= 2) return;

              setAnswersArray(answersArray.slice(0, -1));
              const allAnswers = getValues(`questions.${index}.answers`);
              setValue(`questions.${index}.answers`, allAnswers.slice(0, -1));
            }}
          >
            احذف خيار
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}

function Answer({ index, index2 }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          mt: "10px",
          // flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", mr: "20px" }}>
          <Box sx={{}}>
            <TextField
              inputProps={{
                style: {
                  // color: "black",
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                  WebkitTextFillColor: "#2a2a2a",
                },
              }}
              {...register(`questions.${index}.answers.${index2}.title`, {
                required: "نص الخيار مطلوب",
              })}
              error={errors?.questions?.[index]?.answers?.[index2]?.title}
              helperText={
                errors?.questions?.[index]?.answers?.[index2]?.title?.message
              }
              size="small"
              fullWidth
              placeholder="ادخل الخيار"
              label="خيار"
              type="text"
              sx={{
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000",
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: "#000",
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                "& input::placeholder": {
                  textAlign: "right",
                },
                direction: "ltr",
              }}
              InputProps={{
                style: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <FormControlLabel
          sx={{
            // mb: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // mr: -3,
          }}
          value={index2}
          control={<Radio />}
        />{" "}
      </Box>
    </>
  );
}
