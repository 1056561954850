import { COLORS } from "@/constants/Colors/colors";
import { Typography } from "@mui/material";

export default function NoItemsText({ text, sx }) {
  return (
    <Typography
      sx={{
        fontFamily: "Noor-bold",
        fontSize: "30px",
        textShadow: `0px 2px 0px ${COLORS.PRIMARY.MAIN}`,
        textAlign: "center",
        color: COLORS.DARK_GRAY,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
}
