import { COLORS } from "@/constants/Colors/colors";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useRef, useState } from "react";
import { notifySuccess, notifyError } from "@shared/Toast/toast";
import axios from "axios";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { api_url } from "@/constants/base_url";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import Modal from "@shared/Modal/Modal";
import PropTypes from "prop-types";

export default function AddSupervisor({ modal, closeModal, refetch }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const timerRef = useRef();
  const {
    register,
    handleSubmit,
    formState,
    control,
    setError,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      password: "",
      rePassword: "",
    },
  });

  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const { name, phone, password, rePassword } = getValues();

      if (name.length <= 4) {
        setError("name", {
          type: "manual",
          message: "الحروف يجب أن تكون أكثر من 4",
        });
        return;
      } else {
        clearErrors("name");
      }

      if (!phone.startsWith("01")) {
        setError("phone", { type: "manual", message: "رقم الهاتف غير صحيح" });
        return;
      } else {
        clearErrors("phone");
      }

      if (password.length <= 8 || password !== rePassword) {
        setError("password", {
          type: "manual",
          message: "كلمة المرور غير صحيحة أو يجب أن تكون أكثر من 8 أحرف",
        });
        setError("rePassword", {
          type: "manual",
          message: "كلمة المرور غير صحيحة أو يجب أن تكون أكثر من 8 أحرف",
        });
        return;
      } else {
        clearErrors("password");
        clearErrors("rePassword");
      }

      const formData = {
        name,
        phone,
        password,
        password_confirmation: rePassword,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${currentUser()?.token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${api_url}supervisors/add`,
        formData,
        config
      );
      console.log(response);
      notifySuccess("تمت الاضافه بنجاح");
      closeModal();
      reset();
      refetch();
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending notification:", error);
      notifyError("حدث خطا اثناء التنفيذ");
      setIsLoading(false);
    }
  };
  return (
    <Modal maxWidth={"sm"} open={modal} closeFn={closeModal}>
      <Typography
        fontFamily={"Noor-bold"}
        fontSize={30}
        sx={{ textShadow: "0px 3px 0px #000", color: "black" }}
      >
        انشاء مشرف جديد
      </Typography>

      <form
        onClick={handleSubmit(onSubmit)}
        sx={{ width: "85%", margin: "auto" }}
      >
        <Box sx={{ marginY: "20px" }}>
          <TextField
            inputProps={{
              style: {
                // color: "black",
                WebkitBoxShadow: "0 0 0 1000px white inset",
                WebkitTextFillColor: "#2a2a2a",
              },
            }}
            placeholder="ادخل الاسم بالكامل "
            label=" الاسم بالكامل"
            size="small"
            fullWidth
            {...register("name", {
              required: "هذا الحقل مطلوب",
            })}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: COLORS.DARK_GRAY,
              },
              "& label": {
                left: "unset",
                right: "1.75rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: COLORS.DARK_GRAY,
              },
              "& legend": {
                textAlign: "right",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <span
            style={{
              color: "red",
              fontSize: "14px",
              my: "0px",
              textAlign: "end",
            }}
          >
            {formState.errors.name?.message}
          </span>
        </Box>

        <Box sx={{ marginTop: "30px", marginBottom: "20px" }}>
          <TextField
            inputProps={{
              style: {
                WebkitBoxShadow: "0 0 0 1000px white inset",
                WebkitTextFillColor: "#2a2a2a",
              },
            }}
            placeholder="ادخل رقم الهاتف "
            label=" رقم الهاتف"
            fullWidth
            multiline
            {...register("phone", {
              required: "هذا الحقل مطلوب",
            })}
            sx={{
              "& .MuiFormLabel-root.Mui-focused": {
                color: COLORS.DARK_GRAY,
              },
              "& label": {
                left: "unset",
                right: "1.75rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: COLORS.DARK_GRAY,
              },
              "& legend": {
                textAlign: "right",
                fontSize: "0.6rem",
              },
              direction: "rtl",
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
          <span
            style={{
              color: "red",
              fontSize: "14px",
              my: "0px",
              textAlign: "end",
            }}
          >
            {formState.errors.phone?.message}
          </span>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", marginTop: "0" }}>
          <Box>
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                right: "unset",
                left: "-1.5rem",
                top: "0.50rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: COLORS.DARK_GRAY,
                backgroundColor: "white",
                zIndex: "2",
                width: "50px",
              }}
            >
              كلمه السر
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              placeholder="ادخل كلمة السر "
              fullWidth
              {...register("password", {
                required: "هذا الحقل مطلوب",
              })}
              sx={{
                "& input": {
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                  WebkitTextFillColor: "#2a2a2a",
                },
                "& .MuiInput-root.Mui-focused": {
                  color: COLORS.DARK_GRAY,
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: COLORS.DARK_GRAY,
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <span
              style={{
                color: "red",
                fontSize: "14px",
                my: "0px",
                textAlign: "end",
              }}
            >
              {formState.errors.password?.message}
            </span>
          </Box>
          <Box>
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                right: "unset",
                left: "-1.9rem",
                top: "0.50rem",
                transformOrigin: "right",
                fontSize: "0.8rem",
                color: COLORS.DARK_GRAY,
                backgroundColor: "white",
                zIndex: "2",
                width: "70px",
              }}
            >
              تاكيد كلمه السر
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password2"
              type={showPassword ? "text" : "password"}
              placeholder="ادخل كلمة السر "
              fullWidth
              {...register("rePassword", {
                required: "هذا الحقل مطلوب",
              })}
              sx={{
                "& input": {
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                  WebkitTextFillColor: "#2a2a2a",
                },
                "& .MuiInput-root.Mui-focused": {
                  color: COLORS.DARK_GRAY,
                },
                "& label": {
                  left: "unset",
                  right: "1.75rem",
                  transformOrigin: "right",
                  fontSize: "0.8rem",
                  color: COLORS.DARK_GRAY,
                },
                "& legend": {
                  textAlign: "right",
                  fontSize: "0.6rem",
                },
                direction: "rtl",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <span
              style={{
                color: "red",
                fontSize: "14px",
                my: "0px",
                textAlign: "end",
              }}
            >
              {formState.errors.rePassword?.message}
            </span>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "50px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            sx={{
              background: COLORS.PRIMARY.MAIN,
              width: "250px",
              padding: "10px 25px",
              color: "white",
              cursor: "pointer",
              transition: "0.4s",
              "&:hover": {
                background: "white",
                color: COLORS.PRIMARY.MAIN,
              },
            }}
          >
            انشاء
            {/* {isLoading ? <HashLoader color="#ffffff" size={24} /> : "انشاء"} */}
          </Button>
        </Box>
      </form>
    </Modal>
  );
}
AddSupervisor.propTypes = {
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
};
