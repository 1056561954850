import { initialSwapState } from "./utils";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";

export const ChangeOrderButton = ({ state, setState, isLoading }) => {
  return (
    <ColorButton
      variant="contained"
      sx={{
        color: state.active ? "#000" : "#fff",
        bgcolor: state.active ? "#fff" : "#000",
        "&:hover": {
          color: state.active ? "#000" : "#fff",
          bgcolor: state.active ? "#fff" : "#000",
        },
      }}
      disabled={isLoading}
      onClick={() => {
        if (!state.active) {
          setState((s) => {
            return { ...s, active: true };
          });
        } else {
          setState(initialSwapState);
        }
      }}
    >
      تغيير الترتيب
    </ColorButton>
  );
};
