import { Box, Typography } from "@mui/material";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import loginImage from "@assets/images/Exams-bro.png";
import logo from "@assets/images/logo.png";
import UnstyledLink from "@shared/Links/UnstyledLink";
import TextButton from "@/components/Buttons/TextButton/TextButton";
import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import { COLORS } from "@/constants/Colors/colors";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import { FONTS } from "@/constants/Fonts/fonts";
import BorderedInput from "@/components/Form/BorderedInput/BorderedInput";
import { useForm, FormProvider } from "react-hook-form";
import ColorCheckBox from "@/components/Form/ColorCheckBox/ColorCheckBox";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginSchema } from "../../../shema/login.schema";
import { useLogin } from "../../../hooks/login.hook";
import Column from "@/components/Layout/Column/Column";
import Row from "@/components/Layout/Row/Row";
import { features } from "@config/features";

export default function Login() {
  const { isPending, onSubmit } = useLogin();

  const methods = useForm({
    resolver: zodResolver(loginSchema),
  });
  const { handleSubmit } = methods;

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
        position={"relative"}
        overflow={"hidden"}
        height={"100vh"}
      >
        <Box width={"100%"} position={"absolute"} top={"40px"} right={"60px"}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: "5%",
              padding: "5px",
            }}
          />
        </Box>

        <img
          src={loginImage}
          style={{
            zIndex: -1,
            width: "35%",
            paddingBottom: 20,
          }}
        />

        <Column spacing={"space-between"} width={"50%"} gap={4}>
          <Typography
            fontSize={50}
            textAlign={"center"}
            fontFamily={FONTS.REGULAR}
            color={COLORS.DARK_GRAY}
          >
            تسجيل الدخول
          </Typography>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: "60%",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <BorderedInput
                placeholder="رقم الهاتف"
                registerName="phone"
                Icon={PhoneInTalkOutlinedIcon}
              />
              <BorderedInput
                placeholder="كلمة المرور"
                registerName="password"
                type="password"
                Icon={LockOutlinedIcon}
              />
              <Row spacing={"space-between"} width={"100%"}>
                <ColorCheckBox label={"تذكرني دائما"} />

                <Typography
                  fontFamily={FONTS.BOLD}
                  fontSize={FONT_SIZES.SMALL}
                  color={COLORS.DARK_GRAY}
                >
                  <UnstyledLink to={"/enter-phone-number"}>
                    نسيت كلمة المرور؟
                  </UnstyledLink>
                </Typography>
              </Row>

              <ColorButton
                width={"100%"}
                colorType="submit"
                type="submit"
                isLoading={isPending}
              >
                تسجيل الدخول
              </ColorButton>
              {/* <ColorButton
                onClick={() => {
                  // throw new Error("error");
                  // try {
                  //   throw new Error("error");
                  // } catch (err) {
                  //   Sentry.captureException(err);
                  // }
                }}
                width={"100%"}
                isLoading={isPending}
              >
                error
              </ColorButton> */}
              {features.student && (
                <TextButton>
                  <UnstyledLink to={"/signup"}>او اشترك الان</UnstyledLink>
                </TextButton>
              )}
            </form>
          </FormProvider>
        </Column>
      </Box>
    </>
  );
}
