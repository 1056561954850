import { rest } from "msw";
import { api_url } from "@/constants/base_url";
import { data } from "../data/courses";

const url = api_url + "course/courseData";

const successHandler = rest.post(url, (req, res, ctx) => {
  const id = req.url.searchParams.get("id");

  const course = data.find((course) => course.id === Number(id));
  if (course == null) return res(ctx.status(404));
  return res(ctx.json(course));
});

export const handlers = [successHandler];
