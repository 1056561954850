import { api_url } from "@/constants/base_url";
import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import HashLoader from "react-spinners/HashLoader";
import PropTypes from "prop-types";

function DeleteSubscribtion({
  closeSubNote,
  noteSubscribe,
  subscribtionId,
  refetch,
}) {
  const { mutate, isPending, error, isError, status } = useMutation({
    mutationFn: (subscribtionId) =>
      axios.delete(
        `${api_url}subscription/deleteUserSubscriptions?subscription_id=${subscribtionId}`,
        {
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser()?.token}`,
          },
        }
      ),
    onSuccess: (res) => {
      console.log(res);
      closeSubNote();
      refetch();
      notifySuccess("تم حذف اشتراك الطالب من هذا الكورس");
    },
    onError: (error) => {
      console.log(error);
      notifyError("حدث خطأ اثناء تنفيذ المهمة");
    },
  });
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteSubscribe === "subscribtion" ? "1" : "0",
          visibility: noteSubscribe === "subscribtion" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            padding: "40px 30px",
            borderRadius: "10px",
            width: "450px",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteSubscribe === "subscribtion" ? "1" : "0",
            transform:
              noteSubscribe === "subscribtion" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeSubNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: "#000",
                },
              }}
            />
          </div>

          <Typography
            fontFamily={"Noor-bold"}
            fontSize={30}
            sx={{ textShadow: "0px 3px 0px #000" }}
          >
            هل تريد حذف اشتراك الطالب من هذا الكورس
          </Typography>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alidngItems: "center",
            }}
          >
            <Button
              onClick={() => mutate(subscribtionId)}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "darkred",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              {isPending ? <HashLoader color="#ffffff" size={24} /> : "أوافق"}
            </Button>
            <Button
              onClick={closeSubNote}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "gray",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "lightgray",
                },
              }}
            >
              الغاء
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

DeleteSubscribtion.propTypes = {
  refetch: PropTypes.func,
  noteSubscribe: PropTypes.bool,
  closeSubNote: PropTypes.func,
  subscribtionId: PropTypes.number,
};

export default DeleteSubscribtion;
