import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

export default function CustomNoData({ numOfCells, rowText, button = null }) {
  return (
    <TableRow sx={{ width: "100%" }}>
      <TableCell
        colSpan={numOfCells}
        style={{ textAlign: "center", padding: "30px", width: "100%" }}
      >
        <Typography
          fontFamily="Noor-bold"
          fontSize={30}
          color="black"
          sx={{ textShadow: "0px 3px 0px #000", textAlign: "center" }}
        >
          {rowText}
        </Typography>
        {button}
      </TableCell>
    </TableRow>
  );
}
