import useCustomMutation from "@/hooks/useCustomMutation";
import { queryClient } from "@config/queryClient.config";
import { api_url } from "@/constants/base_url";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export default function useDeleteDrive({ id, deleteName, onSettled }) {
  console.log(`deleteName`, deleteName, `id`, id);

  const { mutate: deleteDriveMutation, isPending: isDriveLoading } =
    useMutation({
      mutationFn: () =>
        axios.delete(
          `${api_url}drive/${id}`,
          // { id, file_name: deleteName, type: "Module" },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${currentUser()?.token}`,
            },
          }
        ),
      onSuccess: () => {
        notifySuccess("تم حذف الملف بنجاح");
        queryClient.invalidateQueries(["file", id]);
      },
      onError: (error) => {
        console.log(error);
        notifyError(error?.response?.data?.message);
      },
    });

  function deleteDrive() {
    deleteDriveMutation();
  }

  return { deleteDrive, isDriveLoading };
}
