import useCustomMutation from "@/hooks/useCustomMutation";
import { queryClient } from "@config/queryClient.config";
import { notifyError, notifySuccess } from "@shared/Toast/toast";

export default function useDeleteHomeWork({ id, onSettled }) {
  console.log(`id`, id);

  const { mutate: deleteHomeWorkMutation, isPending } = useCustomMutation({
    url: `homeWork/deleteHomeWork?homework_id=${id}`,
    invalidate: ["module homework", id],
    method: "delete",
    onSettled,
    onSuccess: () => {
      notifySuccess("تم حذف الواجب بنجاح");
      queryClient.invalidateQueries(["module homework"]);
    },
    onError: (err) => {
      console.log(err);
      notifyError(err?.response?.data?.message);
    },
  });

  function deleteHomeWork() {
    deleteHomeWorkMutation();
  }

  return { deleteHomeWork, isPending };
}
