import { COLORS } from "@/constants/Colors/colors";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function CourseCard({
  name,
  teacher,
  price,
  img,
  type,
  link,
  width,
  description,
  bought,
}) {
  return (
    <>
      <Card
        sx={{
          marginBottom: 2,
          height: 425,
          overflow: "hidden",
          width: width,
          borderRadius: "20px",
          position: "relative",
          bgcolor: COLORS.PRIMARY.MAIN,
          "&:hover > div": {
            transform: "translateY(-270px)",
          },
          transitionDuration: "0.5s",
          transitionProperty: "transform",
        }}
      >
        <Box
          sx={{
            transitionDuration: "0.5s",
            transitionProperty: "transform",
          }}
        >
          <CardMedia
            sx={{
              height: "346px",
              backgroundPosition: "center",
              backgroundSize: "contain",
              bgcolor: "white",
            }}
            image={img}
            title="course"
          />
          <Box
            mt={1}
            mb={5}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            px={2}
            bgcolor={COLORS.PRIMARY.MAIN}
          >
            <Box color={"white"}>
              {/* <Typography fontFamily={"Noor-bold"} fontWeight={'bold'}>{name}</Typography> */}
              <Typography fontFamily={"Noor-bold"}>{name}</Typography>
              <Typography fontFamily={"Noor-bold"} fontSize={12}>
                {description}
              </Typography>
            </Box>
            {!bought && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                color={"white"}
              >
                <Typography
                  fontSize={18}
                  fontFamily={"Noor-bold"}
                  // color={color}
                  mb={-0.5}
                >
                  {price == 0 ? "مجاني" : `${price}`}
                </Typography>
                {price != 0 && (
                  <Typography
                    fontSize={18}
                    fontFamily={"Noor-bold"}
                    // color={color}
                  >
                    جنيه
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: COLORS.PRIMARY.MAIN,
            width: "100%",
            height: "440px",
            transitionDuration: "0.5s",
            transitionProperty: "transform",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to={link}
            style={{
              textDecoration: "none",
              background: "white",
              padding: "7px 20px",
              marginBottom: "20px",
              borderRadius: "5px",
            }}
          >
            <Typography
              fontSize={24}
              fontFamily={"Noor-bold"}
              color={COLORS.PRIMARY.MAIN}
            >
              {bought
                ? "استكمال الكورس"
                : type === "بالكورس"
                ? "احجز الكورس"
                : "اشترك في حصة"}
            </Typography>
          </Link>
        </Box>
      </Card>
    </>
  );
}
CourseCard.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  img: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
  width: PropTypes.string,
  description: PropTypes.string,
  bought: PropTypes.bool,
};
