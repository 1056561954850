import { COLORS } from "@/constants/Colors/colors";
import { FONT_SIZES } from "@/constants/Fonts/font_sizes";
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import useCustomQuery from "@/hooks/useCustomQuery";
import Column from "@/components/Layout/Column/Column";
import CustomNoData from "@/components/Layout/NoDataTableRow/CustomNoData";
import Row from "@/components/Layout/Row/Row";
import CustomTableHead from "@/components/Layout/TableHead/CustomTableHead";
import CustomTableRowSupervisor from "@/components/Layout/TableRow/CustomTableRowSupervisor";
import CustomTableLoading from "@/components/Layout/TableRowLoading/CustomTableLoading";
import Paginator from "@/components/Pagination/Paginator";
import { useState } from "react";
import AddSupervisor from "@/modules/supervisor/components/AddSupervisor";

export default function SupervisorsPage() {
  const tableHeader = ["الرقم", "الاسم", "رقم التليفون", "الدور", "الإعدادات"];
  const [page, setPage] = useState(1);

  const {
    data: tableData,

    isLoading,
    refetch,
  } = useCustomQuery({
    url: `supervisors/getAll?page=${page}&limit=10`,
    key: "supervisors",
    page: page,
  });

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box backgroundColor={COLORS.OFF_WHITE} sx={{ height: "77vh" }}>
      <AddSupervisor
        refetch={refetch}
        closeModal={() => setOpenModal(false)}
        modal={openModal}
      />
      <Grid item={true} xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "20px",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <Box>
            <Stack spacing={2} direction="row">
              <Typography
                fontFamily={"Noor-bold"}
                fontSize={FONT_SIZES.LARGE}
                sx={{ color: COLORS.DARK_GRAY }}
              >
                المسؤولين
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Button
              onClick={() => setOpenModal(true)}
              sx={{
                background: COLORS.PRIMARY.MAIN,
                width: "200px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "white",
                  color: COLORS.PRIMARY.MAIN,
                },
                position: "relative",
              }}
            >
              انشاء مشرف جديد
            </Button>
          </Box>
        </Box>
        <Row>
          <Column
            gap={1}
            spacing={"space-between"}
            align={"flex-start"}
            width={"100%"}
            marginBottom={"20px"}
          >
            <Paginator
              page={page}
              totalPages={tableData?.last_page}
              setPage={setPage}
            />
          </Column>
        </Row>
        <Row>
          <Column
            gap={1}
            spacing={"space-between"}
            align={"flex-start"}
            width={"100%"}
          >
            <TableContainer>
              <Table>
                <CustomTableHead
                  headBackground={COLORS.PRIMARY.MAIN}
                  tableHeaders={tableHeader}
                />

                {isLoading ? (
                  <CustomTableLoading
                    width={"100%"}
                    height={60}
                    numOfCells={7}
                  />
                ) : (
                  <>
                    {tableData?.data?.data.length > 0 ? (
                      <>
                        {tableData?.data?.data.map((supervisor, i) => (
                          <CustomTableRowSupervisor
                            refetch={refetch}
                            id={supervisor.id}
                            key={supervisor.id || "-"}
                            number={supervisor?.id || "-"}
                            name={supervisor?.name}
                            phone={supervisor?.phone}
                            role={(supervisor.pivot.role_id = "مشرف")}
                          />
                        ))}
                      </>
                    ) : (
                      <CustomNoData rowText={"لا يوجد مشرفين"} numOfCells={7} />
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Column>
        </Row>
      </Grid>
    </Box>
  );
}
