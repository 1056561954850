import React, { useState } from "react";
import useCustomQuery from "@/hooks/useCustomQuery";
import { Box, Button, Typography } from "@mui/material";
import { ClipLoader } from "react-spinners";
import { COLORS } from "@/constants/Colors/colors";
import { Bar } from "./atoms/Bar";
import { AddBar } from "./atoms/AddBar";
export const Bars = () => {
  const [add, setAdd] = useState(false);
  const { data, isPending } = useCustomQuery({
    key: ["bar"],
    url: "bar/getAll",
    select: (data) => data?.data?.data,
  });
  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        bgcolor: COLORS.WHITE,
        fontFamily: "Noor-regular",
        borderRadius: 2,
        boxShadow: "0 0 8px rgba(0,0,0,0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontFamily: "Noor-regular",
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontFamily: "Noor-regular",
            color: COLORS.PRIMARY.MAIN,
          }}
        >
          البانرز المثبتة
        </Typography>
        <Button
          onClick={() => {
            setAdd(true);
          }}
          sx={{
            fontFamily: "Noor-regular",
            color: "white",
            bgcolor: COLORS.PRIMARY.MAIN,
            fontSize: "18px",
            "&:hover": { bgcolor: COLORS.SECONDARY.MAIN },
          }}
        >
          أضف
        </Button>
      </Box>
      {isPending && <ClipLoader size={60} />}
      {!isPending &&
        (() => {
          if (data?.length > 0) {
            return (
              <>
                {data?.map((item) => (
                  <Bar message={item} key={item.id} />
                ))}
              </>
            );
          } else {
            return (
              <Typography sx={{ fontFamily: "Noor-regular" }}>
                لا توجد بانرز مثبتة
              </Typography>
            );
          }
        })()}
      <AddBar open={add} setOpen={setAdd} />
    </Box>
  );
};
