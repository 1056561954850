import useCustomMutation from "@/hooks/useCustomMutation";
import { queryClient } from "@config/queryClient.config";
import { notifyError, notifySuccess } from "@shared/Toast/toast";

export default function useDeleteModule({ id, onSettled }) {
  const { mutate: deleteModuleMutation, isPending: isModuleLoading } =
    useCustomMutation({
      url: `module/delete?id=${id}`,
      invalidate: ["course modules", id],
      method: "get",
      onSettled,
      onSuccess: () => {
        notifySuccess("تم حذف الحصة بنجاح");
        queryClient.invalidateQueries(["course modules"]);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    });

  function deleteModule() {
    deleteModuleMutation();
  }

  return { deleteModule, isModuleLoading };
}
