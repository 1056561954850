import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import VideocamIcon from "@mui/icons-material/Videocam";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import style from "./AddVideo.module.css";
import plusIcon from "../../../../../../../assets/adminImages/plusIcon.png";
import Form from "./../../../../../../../components/Form/Form/Form";
import { COLORS } from "@/constants/Colors/colors";
import { newVideoSchema } from "../../../schema/editVideoSchema";
import useAddVideo from "../../hook/useAddVideo";
import Modal from "@shared/Modal/Modal";

export default function AddVideo({ title, addRowInSession, module, course }) {
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const methods = useForm({
    resolver: zodResolver(newVideoSchema),
  });

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const { addModuleVideo, isPending } = useAddVideo({
    onSuccess: () => {
      setOpen(false);
      close();
    },
  });

  function submit(data) {
    if (!data.name && data.video?.name) {
      const videoNameWithoutExtension = data.video.name
        .split(".")
        .slice(0, -1)
        .join(".");
      data.name = videoNameWithoutExtension;
    }

    addModuleVideo({
      ...data,
      module_id: module?.id,
      available_web: watch("available_web") ? 1 : 0,
    });
  }

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  return (
    <>
      <Button
        sx={{
          bgcolor: "#b93232",
          display: "block",
          color: COLORS.WHITE,
          width: 150,
          height: 150,
          "&:hover": { bgcolor: "#b93232", opacity: "0.9" },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <VideocamIcon fontSize="large" />
        <Typography>{"فيديو"}</Typography>
      </Button>
      <Modal open={open} closeFn={() => setOpen(false)} maxWidth={"sm"}>
        <FormProvider {...methods}>
          <Form title=" اضف فيديو" onSubmit={handleSubmit(submit)}>
            <Form.TextController
              register={register}
              registername="name"
              placeholder={"ادخل اسم الفيديو "}
              errorMessage={errors?.name && errors?.name?.message}
            />

            <label className={style.toggleContainer}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div className={style.toggle}>
                <p style={{ color: COLORS.DARK_GRAY }}>{"الجهاز"}</p>
                <p style={{ color: COLORS.DARK_GRAY }}>{"الرابط"}</p>
              </div>
            </label>

            {isChecked ? (
              <Form.TextController
                register={register}
                registername="link"
                placeholder={"ادخل رابط الفيديو"}
                validationRules={{
                  required: "رابط الفيديو مطلوب",
                  validate: (value) => {
                    const youtubeRegex =
                      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?(\S+)?$/;
                    const isYoutubeLink = youtubeRegex.test(value);

                    if (!isYoutubeLink) {
                      return "رابط الفيديو غير صالح";
                    }
                    return true;
                  },
                }}
                errorMessage={errors?.link && errors?.link?.message}
              />
            ) : (
              <Form.DragAndDropController
                register={register}
                registername="video"
                setValue={setValue}
                errors={errors}
                working={isChecked}
              />
            )}
            <FormControlLabel
              sx={{
                // mb: 2,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mr: -3,
              }}
              control={
                <Checkbox
                  {...register("available_web")}
                  defaultChecked={false}
                />
              }
              label="اتاحة للويب"
            />

            <Form.ButtonController isPending={isPending} type="submit">
              رفع الفيديو
            </Form.ButtonController>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
}
