import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import HashLoader from "react-spinners/HashLoader";
import { useRemoveBanStudent } from "../../../hooks/useRemoveBanStudent";
import PropTypes from "prop-types";

function RemoveBan({ id, closeNote, noteBook, refetch }) {
  const { removeBanStudent, isPending } = useRemoveBanStudent({
    onSettled: close,
  });

  const handleRemove = () => {
    refetch();
    if (id) {
      removeBanStudent({ id: id });
      closeNote();
      refetch();
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          bgcolor: "#000000ab",
          zIndex: "1501",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s",
          opacity: noteBook === "remove-ban" ? "1" : "0",
          visibility: noteBook === "remove-ban" ? "visible" : "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            padding: "30px",
            borderRadius: "10px",
            width: "400px",
            height: "auto",
            textAlign: "center",
            position: "relative",
            transition: "0.3s",
            opacity: noteBook === "remove-ban" ? "1" : "0",
            transform: noteBook === "remove-ban" ? "scale(1)" : "scale(0)",
          }}
        >
          <div
            className="close-btn"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 100,
            }}
          >
            <Close
              onClick={closeNote}
              sx={{
                color: "black",
                width: 30,
                height: 30,
                transition: "0.4s",
                cursor: "pointer",
                "&:hover": {
                  color: "#000",
                },
              }}
            />
          </div>

          <Typography
            fontFamily={"Noor-bold"}
            fontSize={30}
            sx={{ textShadow: "0px 3px 0px #000" }}
          >
            هل تريد فك حظر هذا الطالب
          </Typography>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alidngItems: "center",
            }}
          >
            <Button
              onClick={() => handleRemove()}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "darkred",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "red",
                },
              }}
            >
              {isPending ? <HashLoader color="#ffffff" size={24} /> : "أوافق"}
            </Button>
            <Button
              onClick={closeNote}
              sx={{
                padding: "7px 20px",
                width: "49%",
                bgcolor: "gray",
                color: "white",
                trnsition: "0.4s",
                "&:hover": {
                  bgcolor: "lightgray",
                },
              }}
            >
              الغاء
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
RemoveBan.propTypes = {
  refetch: PropTypes.func,
  noteBook: PropTypes.bool,
  closeNote: PropTypes.func,
  id: PropTypes.number,
};

export default RemoveBan;
