import { useEffect } from "react";
import {
  AreaChart,
  CartesianGrid,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PropTypes from "prop-types";

export default function AreaChartComp({ data, label, color }) {
  useEffect(() => {}, [label]);
  return (
    <ResponsiveContainer width="100%" minHeight={300}>
      <AreaChart
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0.3} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" dy={10} />
        <YAxis type="number" dx={-30} />
        <CartesianGrid strokeDasharray="6 6" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey={label}
          stroke={color}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

AreaChartComp.propTypes = {
  data: PropTypes.array,
  label: PropTypes.any,
  color: PropTypes.string,
};
