import { ClipLoader } from "react-spinners";
import { ColorButton } from "../ColorButton";

export const ConfirmSwapBtn = ({ state, isLoading, swapFn }) => {
  return (
    state?.first &&
    state?.second && (
      <ColorButton disabled={isLoading} onClick={swapFn}>
        {isLoading ? <ClipLoader color="#fff" size={20} /> : "تأكيد"}
      </ColorButton>
    )
  );
};
