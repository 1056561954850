import { Edit, Wallet } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ClipLoader } from "react-spinners";
import { useEffect } from "react";
import NoItemsText from "@shared/NoItemText/NoItemText";
import useUpdateStudent from "../../../hooks/useUpdateStudent";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import PropTypes from "prop-types";

export default function StudentWallet({ money, id }) {
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newValue, setNewValue] = useState(parseInt(money));
  const { mutate, isLoading, isSuccess } = useUpdateStudent();
  useEffect(() => {
    if (isSuccess) {
      setEdit(false);
    }
  }, [isSuccess]);
  return (
    <>
      {currentUser()?.role == "Admin" && (
        <IconButton
          className="settingIcon"
          onClick={() => {
            setIsOpen(true);
          }}
          aria-label="delete"
          size="small"
          sx={{
            bgcolor: "#810305",
            marginRight: "10px",
            "&:hover": {
              bgcolor: "#810305",
            },
          }}
        >
          <span className="settingHover">المحفظة</span>
          <Wallet fontSize="inherit" sx={{ color: "#fff" }} />
        </IconButton>
      )}
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <DialogTitle sx={{ borderTop: "#e7f50e 5px solid" }}>
          <Button onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </Button>
        </DialogTitle>{" "}
        <DialogContent>
          <NoItemsText
            text={"المحفظة"}
            sx={{
              textAlign: "center",
              my: 2,
            }}
          />
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {!edit && (
              <Typography textAlign={"center"} fontSize={32}>
                {money} جنيها
              </Typography>
            )}
            {edit && (
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <input
                  type="number"
                  placeholder="ادخل المبلغ الجديد"
                  style={{
                    background: "white",
                    border: "1px solid #e7f50e",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: "black",
                    transition: "0.4s",
                  }}
                  value={newValue}
                  onChange={(e) => setNewValue(e.target.value)}
                  // {...register("price", {
                  //   required: `يجب ادخال السعر`,
                  // })}
                />
                <Box display={"flex"} gap={2} justifyContent={"center"}>
                  <Button
                    onClick={() => {
                      mutate({ id: id, money: newValue });
                    }}
                    sx={{
                      background: "#e7f50e",
                      width: "100px",
                      padding: "10px 5px",
                      color: "white",
                      cursor: "pointer",
                      transition: "0.4s",
                      "&:hover": {
                        background: "#e7f50e",
                      },
                    }}
                  >
                    {isLoading ? (
                      <ClipLoader size={25} color="white" />
                    ) : (
                      "تعديل"
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      setNewValue(parseInt(money));
                      setEdit(false);
                    }}
                    sx={{
                      background: "#e7f50e",
                      width: "100px",
                      padding: "10px 5px",
                      color: "white",
                      cursor: "pointer",
                      transition: "0.4s",
                      "&:hover": {
                        background: "#e7f50e",
                      },
                    }}
                  >
                    الغاء
                  </Button>
                </Box>
              </Box>
            )}
            {!edit && (
              <IconButton onClick={() => setEdit(true)}>
                <Edit />
              </IconButton>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

StudentWallet.propTypes = {
  money: PropTypes.string,
  id: PropTypes.number,
};
