import useCustomQuery from "@/hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { COLORS } from "@/constants/Colors/colors";
import { HashLoader } from "react-spinners";
import CourseFileBox from "../../../Admin/Pages/Course/CourseFiles/CourseFileBox";
import { CreateCourseFile } from "../../../Admin/Pages/Course/CourseFiles/CreateCourseFile";

export const CourseFiles = () => {
  const { id } = useParams();

  const { data, isPending, isError } = useCustomQuery({
    url: `drive?type=course&id=${id}`,
    method: "get",
    key: [`files`, id],
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: 30,
          color: COLORS.PRIMARY.MAIN,
          fontFamily: "Noor-bold",
        }}
      >
        الملفات
      </Typography>
      <CreateCourseFile id={id} />
      {isPending ? (
        <Box marginTop={2}>
          <HashLoader size={20} color={COLORS.PRIMARY.MAIN} />
        </Box>
      ) : isError ? (
        <></>
      ) : data?.data?.length == 0 ? (
        <Typography sx={{ fontSize: 30, fontFamily: "Noor-bold" }}>
          لا يوجد ملفات
        </Typography>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gap: 1,
            width: "100%",
          }}
        >
          {data?.data?.map((item) => (
            <CourseFileBox item={item} />
          ))}
        </Box>
      )}
    </Box>
  );
};
