import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import ColorButton from "@shared/Buttons/ColorButton/ColorButton";
import CloseIcon from "@mui/icons-material/Close";
import useDeleteVideo from "./useDeleteVideo";
import useDeleteHomeWork from "./useDeleteHomeWork";
import useDeleteExam from "./useDeleteExam";
import useDeleteDrive from "./useDeleteDrive";
import useDeleteModule from "../ModuleDetailsCollapse/hook/useDeleteModule";
export default function DeleteDialog({
  isConfirmOpen,
  setIsConfirmOpen,
  deleteName,
  deleteData,
  confirmMessage,
  id,
}) {
  const { deleteVideo, isPending } = useDeleteVideo({ id: deleteData?.id });
  const { deleteExam, isExamLoading } = useDeleteExam({ id: deleteData?.id });
  const { deleteDrive, isDriveLoading } = useDeleteDrive({
    id,
    deleteName,
  });
  const { deleteHomeWork, isPending: isLoading } = useDeleteHomeWork({
    id: deleteData?.id,
  });
  const { deleteModule, isPending: isModuleLoading } = useDeleteModule({
    id: deleteData?.id,
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={() => setIsConfirmOpen(false)}
      open={isConfirmOpen}
    >
      <DialogTitle sx={{ borderTop: "#000 5px solid" }}>
        <Button onClick={() => setIsConfirmOpen(false)}>
          <CloseIcon />
        </Button>
      </DialogTitle>{" "}
      <DialogContent
        sx={{
          borderBottom: "#000 5px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography my={4} fontSize={"24px"}>
          {confirmMessage || ""} {deleteName || ""}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <ColorButton
            sx={{
              bgcolor: "#db3a3c",
              transition: "all 0.3s",
            }}
            variant="contained"
            onClick={() => {
              setIsConfirmOpen(false);
              switch (confirmMessage) {
                case "هل انت متأكد من حذف الفيديو":
                  deleteVideo(deleteData?.id);
                  break;
                case "هل انت متأكد من حذف الملف":
                  deleteDrive({ id, deleteName });
                  break;
                case "هل انت متأكد من حذف الواجب":
                  deleteHomeWork(deleteData?.id);
                  break;
                case "هل انت متأكد من حذف الامتحان":
                  deleteExam(deleteData?.id);
                  break;
                case "هل انت متأكد من حذف الحصه":
                  deleteModule(deleteData?.id);
                  break;
              }
            }}
          >
            تأكيد
          </ColorButton>
          <ColorButton
            variant="contained"
            onClick={() => setIsConfirmOpen(false)}
          >
            {" "}
            رجوع
          </ColorButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
