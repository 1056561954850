import { Box, Typography } from "@mui/material";
import { ClipLoader } from "react-spinners";
import PropTypes from "prop-types";
export default function Number({ name, value, hex }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          padding: "30px",
          borderRadius: "15px",
        }}
      >
        {value === undefined && <ClipLoader color={hex} size={60} />}
        {value !== undefined && (
          <Typography
            sx={{
              fontSize: "60px",
              fontWeight: "bold",
              color: hex,
              fontFamily: "Noor-regular",
            }}
          >
            {value}
          </Typography>
        )}
        <Typography sx={{ fontSize: "24px", fontFamily: "Noor-regular" }}>
          {name}
        </Typography>
      </Box>
    </>
  );
}

Number.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  hex: PropTypes.string,
};
