import Chart from "../components/Chart/Chart";
import Numbers from "../components/Numbers/Numbers";
import Notifications from "../components/Notifications/Notifications";
import { Box } from "@mui/material";
import { Bars } from "../components/Bars/Bars";

export const DashboardHome = () => {
  return (
    <Box sx={{ fontFamily: "Noor-regular" }}>
      <Chart />
      <Numbers />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Bars />
        <Notifications />
      </Box>
    </Box>
  );
};
