import ColorButton from "@/components/Buttons/ColorButton/ColorButton";
import CourseCard from "../components/CourseCard";
import CustomGrid from "@/components/Layout/CustomGrid/CustomGrid";
import Row from "@/components/Layout/Row/Row";
import Paginator from "@/components/Pagination/Paginator";
import Column from "@/components/Layout/Column/Column";
import useCustomQuery from "@/hooks/useCustomQuery";
import { useState } from "react";
import CourseDetailsSideBar from "@/modules/courses/components/CourseDetailsSideBar";
import { useRecoilState } from "recoil";
import { addCourseAtom } from "@/modules/courses/services/RecoilAtoms/addCourseAtom";
import { Box, Skeleton } from "@mui/material";
import { ChangeOrderButton } from "@/Admin/Components/swapOrder/ChangeOrderBtn";
import { ConfirmSwapBtn } from "@/Admin/Components/swapOrder/ConfirmSwapBtn";
import {
  useSwapState,
  getSelectedStyles,
  selectedClickHandler,
} from "@/Admin/Components/swapOrder/utils";
import useSwapCourseOrder from "@/hooks/useSwapCourseOrder";

export default function CoursesHomePage() {
  // ------------ hooks ------------

  const [page, setPage] = useState(1);
  const {
    data: courses,
    isLoading: isCoursesLoading,
    isPending,
    refetch,
  } = useCustomQuery({
    url: `course/all?page=${page}`,
    key: ["courses", page],
    page: page,
  });

  const [, setAddCourse] = useRecoilState(addCourseAtom);
  const { swapState, setSwapState } = useSwapState();

  const { mutate, isLoading } = useSwapCourseOrder(setSwapState);

  // -------------- function --------------

  const content = (() => {
    if (isCoursesLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            width: "100%",
          }}
        >
          {[...Array(3)].map((_, i) => (
            <Skeleton
              key={i}
              width="30%"
              height={500}
              sx={{
                marginBottom: "-0px",
                marginTop: "-100px",
              }}
            />
          ))}
        </div>
      );
    } else {
      return courses?.data
        ?.sort((a, b) => b.order_id - a.order_id)
        ?.map(
          ({
            description,
            duration,
            instructor_id,
            id,
            level,
            name,
            price,
            // image,
          }) => (
            <Box
              width={"100%"}
              key={id}
              sx={getSelectedStyles(id, swapState)}
              onClick={() => {
                selectedClickHandler(id, swapState, setSwapState);
              }}
            >
              <CourseCard
                courseId={id}
                // img={getImageUrl(" " + image) || teacherimg}
                level={level}
                description={description}
                eduStage={level}
                depart={"الشعبة"}
                courseName={name}
                price={price}
                months={duration}
                color={"secondary.main"}
                teacherId={instructor_id}
                customMargin={true}
                additional={false}
              />
            </Box>
          )
        );
    }
  })();

  // -------------- side effects --------------

  return (
    <>
      <Row spacing={"space-between"} align={"flex-start"}>
        <Column
          gap={1}
          spacing={"space-between"}
          align={"flex-start"}
          width={"70%"}
        >
          <Row spacing={"space-between"} mb={2}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <ColorButton onClick={() => setAddCourse(true)} width={"100px"}>
                اضف كورس
              </ColorButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <ChangeOrderButton
                  isLoading={isLoading}
                  state={swapState}
                  setState={setSwapState}
                />
                <ConfirmSwapBtn
                  isLoading={isLoading}
                  state={swapState}
                  swapFn={() =>
                    mutate({
                      first_course_id: swapState.first,
                      second_course_id: swapState.second,
                    })
                  }
                />
              </Box>
            </Box>
            <Paginator
              page={page}
              totalPages={courses?.last_page}
              setPage={setPage}
            />
          </Row>
          <CustomGrid
            data-testid="courses-container"
            isLoading={isPending}
            width={"100%"}
          >
            {content}
            {/* {courses?.data?.map((course) => (
              <CourseCard
                parent={"courses"}
                key={course?.id}
                courseId={course?.id}
              />
            ))} */}
          </CustomGrid>
        </Column>
        <CourseDetailsSideBar refetch={refetch} />
      </Row>
    </>
  );
}
