import { Box, Button, Pagination, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { HashLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { api_url } from "@/constants/base_url";
import { currentUser } from "@shared/Helpers/encryptionHelpers";
import useCustomQuery from "@/hooks/useCustomQuery";
import { COLORS } from "@/constants/Colors/colors";
import PropTypes from "prop-types";

export default function AddSupervisorTeacher({
  noteSuperTeacher,
  setNoteSuperTeacher,
  closeSuperTeacher,
  refetch,
  id,
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      course_id: "",
      supervisor_id: id,
    },
  });
  const courseName = watch("course_id");

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [uid, setUid] = useState();
  const { data: teacherData, isLoading: teacherLoading } = useCustomQuery({
    url: `${api_url}course/all?page=${page}`,
    key: ["all-course", page, id],
    page: page,
    select: (data) => data,
  });

  // useQuery({
  //   queryFn: () =>
  //     axios.get(`${api_url}course/all?page=${page}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${currentUser()?.token}`,
  //       },
  //     }),
  //   onSuccess: (data) => {
  //     setTotalPages(data?.data?.last_page);
  //     refetch();
  //   },
  //   onError: (err) => {
  //     console.log(err);
  //     notifyError("حدث خطأ اثناء تنفيذ المهمة");
  //   },
  // });

  const { mutate, isLoading } = useMutation({
    mutationFn: (formData) =>
      axios.post(`${api_url}supervisors/assignCourse`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentUser()?.token}`,
        },
      }),
    onSuccess: () => {
      notifySuccess("تم اضافة كورس جديد للمشرف");
      refetch();
      reset();
    },
    onError: (err) => {
      console.log(err);
      notifyError("حدث خطأ اثناء تنفيذ المهمة");
    },
    onSettled: () => {
      setNoteSuperTeacher(false);
    },
  });

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const onSubmit = () => {
    const formData = {
      supervisor_id: id.toString(),
      course_id: uid.toString(),
    };
    mutate(formData);
  };
  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100%",
        zIndex: "1500",
        display: "flex",
        padding: "80px 0px",
        alignItems: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: "#000",
        scrollbarWidth: "thin",
        overflowY: "auto",
        opacity: noteSuperTeacher ? 1 : 0,
        visibility: noteSuperTeacher ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: COLORS.OFF_WHITE,
          borderRadius: "10px",
          width: "800px",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: noteSuperTeacher ? 1 : 0,
          transform: noteSuperTeacher ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeSuperTeacher();
              reset();
            }}
            sx={{
              color: COLORS.OFF_WHITE,
              padding: "8px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "secondary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "#000",
              },
            }}
          />
        </div>

        <Typography
          fontFamily={"Noor-bold"}
          mb={3}
          mt={4}
          fontSize={32}
          sx={{ textShadow: "0px 2px 0px #000", textAlign: "center" }}
        >
          اضافة كورس
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                margin: "30px 0px",
              }}
            >
              <Pagination
                count={totalPages}
                color="secondary"
                shape="rounded"
                onChange={handlePageChange}
                page={page}
              />
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginRight: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: "40px",
                  fontSize: "15px",
                  color: "#000",
                  background: "#F5F5F5",
                  padding: "10px 20px",
                }}
              >
                اسم الكورس
              </span>

              <select
                name=""
                id={`course_id_${id}`}
                placeholder="الاسم"
                style={{
                  background: "#F5F5F5",
                  border: "1px solid #000",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: "black",
                  transition: "0.4s",
                }}
                {...register("course_id", {
                  required: `يجب اختيار الاسم`,
                  validate: () =>
                    !["اختر اسم الكورس", "لا يوجد كورسات"].includes(
                      courseName
                    ) || "يجب اختيار اسم الكورس",
                })}
                onChange={(e) => setUid(e.target.value)}
              >
                <option value="" disabled selected>
                  اختر اسم الكورس
                </option>
                {teacherLoading ? (
                  <option value="" disabled>
                    انتظر يتم تحميل الكورسات...
                  </option>
                ) : (
                  <>
                    {teacherData?.data?.data?.length != 0 ? (
                      <>
                        {teacherData?.data?.data?.map((i) => {
                          return (
                            <React.Fragment key={i.id}>
                              <option value={i.id}>{i.name}</option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <option value="" disabled selected>
                          لا يوجد كورسات
                        </option>
                      </>
                    )}
                  </>
                )}
              </select>
              {errors.city ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.city?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="submit"
              sx={{
                background: "#000",
                width: "250px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "#000",
                },
              }}
            >
              {isLoading ? <HashLoader color="#ffffff" size={24} /> : "اضافة"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}
AddSupervisorTeacher.propTypes = {
  noteSuperTeacher: PropTypes.any,
  setNoteSuperTeacher: PropTypes.any,
  closeSuperTeacher: PropTypes.any,
  refetch: PropTypes.func,
  id: PropTypes.number,
};
