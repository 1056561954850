import { TextField, IconButton } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { COLORS } from "@/constants/Colors/colors";
import { useState } from "react";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

export default function BorderedInput(props) {
  const { Icon, registerName, placeholder, type = "text" } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [innerType, setInnerType] = useState("password");

  return (
    <TextField
      inputProps={{
        style: {
          WebkitBoxShadow: "0 0 0 1000px white inset",
          WebkitTextFillColor: COLORS.DARK_GRAY,
        },
      }}
      {...register(registerName)}
      error={!!errors[registerName]}
      helperText={errors[registerName]?.message}
      placeholder={placeholder}
      type={type === "password" ? innerType : type}
      sx={{
        width: "100%",
        "& .MuiFormLabel-root.Mui-focused": {
          color: COLORS.PRIMARY.MAIN,
        },
        "& label": {
          left: "unset",
          right: "1.75rem",
          transformOrigin: "right",
          fontSize: "0.8rem",
          color: "#ED7014",
        },
        "& legend": {
          textAlign: "right",
          fontSize: "0.6rem",
        },
        backgroundColor: COLORS.WHITE,
        direction: "rtl",
      }}
      size="small"
      InputProps={{
        endAdornment: (
          <>
            {type === "password" && (
              <IconButton
                edge="end"
                onClick={() =>
                  setInnerType((t) => (t === "password" ? "text" : "password"))
                }
              >
                {innerType === "password" ? (
                  <VisibilityOutlined />
                ) : (
                  <VisibilityOffOutlined />
                )}
              </IconButton>
            )}
            {!!Icon && (
              <span style={{ paddingLeft: 10, paddingTop: 5 }}>
                <Icon />
              </span>
            )}
          </>
        ),
      }}
    />
  );
}
