import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import useLocalStorageToken from "../Admin/Shared/Helpers/axios/useLocalStorageToken";

import { api_url } from "@/constants/base_url";

export const useMutate = () => {
  const token = useLocalStorageToken();
  const mutation = useMutation({
    mutationFn: async ({ url, body, method }) => {
      try {
        const response = await axios({
          method,
          url: api_url + url,
          data: body,
          headers: {
            Authorization: `Bearer ${token?.token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error(error.response.data || "An error occurred");
      }
    },
  });

  return {
    ...mutation,
  };
};
