import useCustomMutation from "@/hooks/useCustomMutation";

export function useRemoveBanStudent({ onSettled }) {
  const { mutate, isPending } = useCustomMutation({
    url: "users/removeBan",
    invalidate: ["removeBan"],
    method: "post",
    successMsg: "تم فك حظر الطالب",
    onSettled,
  });

  function removeBanStudent(data) {
    const formData = new FormData();
    Object.entries(data).forEach((key) => {
      formData.append(key[0], data[key[0]]);
    });
    mutate(formData);
  }

  return { removeBanStudent, isPending };
}
