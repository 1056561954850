import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useUpdateStudent() {
  const instance = useAxiosConfig();

  function updateStudent(data) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    return instance.post(`users/update`, data, config);
  }
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => updateStudent(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["update student"]);
      queryClient.invalidateQueries(["students"]);
      notifySuccess("تم تعديل بيانات الطالب");
    },
    onError: (err) => {
      notifyError(err?.response?.data?.message);
    },
  });
}
