import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Close,
  RemoveRedEyeOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import { useEffect } from "react";
import { HashLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { governorates } from "../../../hooks/governorates";
import useCreateStudent from "../../../hooks/useCreateStudent";
import PropTypes from "prop-types";

export default function AddStudent({ openAddStudent, closeAddStundet }) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      parent_phone: "",
      city: "",
      password: "",
      password_confirmation: "",
    },
  });

  const password = watch("password", "");
  const selectedCity = watch("city");

  // create function
  const { mutate, isLoading, isSuccess, error } = useCreateStudent();

  const onSubmit = (data) => {
    mutate(data);
  };

  const [passType, setPassType] = useState("password");
  const togglePassword = () => {
    if (passType === "password") {
      setPassType("text");
      return;
    }
    setPassType("password");
  };

  useEffect(() => {
    if (isSuccess) {
      closeAddStundet();
      reset();
    }
  }, [isSuccess]);
  return (
    <div
      className=""
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        background: "#000000ab",
        width: "100%",
        height: "100%",
        zIndex: "1500",
        display: "flex",
        // justifyContent: 'center',
        padding: "80px 0px",
        alignItems: "center",
        flexDirection: "column",
        transition: "0.3s",
        scrollbarColor: "#000",
        scrollbarWidth: "thin",
        overflowY: "auto",
        opacity: openAddStudent ? 1 : 0,
        visibility: openAddStudent ? "visible" : "hidden",
      }}
    >
      <Box
        className="device-box"
        px={3}
        sx={{
          padding: "20px 30px",
          background: "#F5F5F5",
          borderRadius: "10px",
          width: "70%",
          height: "auto",
          // overflowY: 'auto',
          display: "flex",
          flexDirection: "column",
          transition: "0.3s",
          opacity: openAddStudent ? 1 : 0,
          transform: openAddStudent ? "scale(1)" : "scale(0)",
        }}
      >
        <div
          className="close-btn"
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <Close
            onClick={() => {
              closeAddStundet();
              reset();
            }}
            sx={{
              color: "white",
              padding: "8px 10px",
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              transition: "0.4s",
              bgcolor: "secondary.main",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "#000",
              },
            }}
          />
        </div>

        <Typography
          fontFamily={"Noor-bold"}
          mb={6}
          mt={4}
          fontSize={32}
          sx={{ textShadow: "0px 2px 0px #000", textAlign: "center" }}
        >
          انشاء طالب جديد
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // alignItems: 'center',
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                // marginLeft: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: "40px",
                  fontSize: "15px",
                  color: "#000",
                  background: "#F5F5F5",
                  padding: "10px 20px",
                }}
              >
                الاسم بالكامل
              </span>
              <input
                type="text"
                placeholder="الاسم بالكامل"
                style={{
                  background: "#F5F5F5",
                  border: "1px solid #000",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: "black",
                  transition: "0.4s",
                }}
                {...register("name", {
                  required: `يجب ادخال الاسم`,
                  validate: (name) => {
                    // Split the input name into words based on spaces
                    const words = name.split(" ");

                    // Filter out empty words (consecutive spaces)
                    const validWords = words.filter(
                      (word) => word.trim() !== ""
                    );

                    return (
                      validWords.length == 3 ||
                      "لا يمكن أن يكون الاسم أقل أو أكثر من 3 كلمات"
                    );
                  },
                })}
              />
              {errors.name ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.name?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // alignItems: 'center',
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: "40px",
                  fontSize: "15px",
                  color: "#000",
                  background: "#F5F5F5",
                  padding: "10px 20px",
                }}
              >
                رقم الطالب
              </span>
              <input
                type="text"
                placeholder="رقم الطالب"
                style={{
                  background: "#F5F5F5",
                  border: "1px solid #000",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: "black",
                  transition: "0.4s",
                }}
                {...register("phone", {
                  required: `يجب ادخال رقم الطالب`,
                  maxLength: {
                    value: 11,
                    message: "يجب ان يكون 11 رقم فقط",
                  },
                  minLength: {
                    value: 11,
                    message: "يجب ان يكون 11 رقم",
                  },
                  pattern: {
                    value: /^(011|010|012|015)\d{8}$/,
                    message: "الرقم غير صحيح",
                  },
                })}
              />
              {errors.phone ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.phone?.message}
                </Typography>
              ) : (
                ""
              )}
              {error?.response?.data?.errors?.phone?.[0] ===
              "The phone has already been taken." ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  هذا الرقم مستخدم من قبل
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginLeft: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: "40px",
                  fontSize: "15px",
                  color: "#000",
                  background: "#F5F5F5",
                  padding: "10px 20px",
                }}
              >
                رقم ولي الامر
              </span>
              <input
                type="text"
                placeholder=" رقم ولي الامر"
                style={{
                  background: "#F5F5F5",
                  border: "1px solid #000",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: "black",
                  transition: "0.4s",
                }}
                {...register("parent_phone", {
                  required: `يجب ادخال رقم ولي الامر`,
                  maxLength: {
                    value: 11,
                    message: "يجب ان يكون 11 رقم فقط",
                  },
                  minLength: {
                    value: 11,
                    message: "يجب ان يكون 11 رقم",
                  },
                  pattern: {
                    value: /^(011|010|012|015)\d{8}$/,
                    message: "الرقم غير صحيح",
                  },
                  validate: (value, data) => {
                    if (value === data.phone) {
                      return "رقم ولي الامر لا يمكن ان يساوي رقم الطالب";
                    } else {
                      return true;
                    }
                  },
                })}
              />
              {errors.parent_phone ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.parent_phone?.message}
                </Typography>
              ) : (
                ""
              )}
              {error?.response?.data?.errors?.phone?.[0] ===
              "The phone has already been taken." ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  هذا الرقم مستخدم من قبل
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginBottom: "40px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: "40px",
                  fontSize: "15px",
                  color: "#000",
                  background: "#F5F5F5",
                  padding: "10px 20px",
                }}
              >
                المحافظة
              </span>

              <select
                name=""
                id=""
                placeholder="المحافظة"
                style={{
                  background: "#F5F5F5",
                  border: "1px solid #000",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: "black",
                  transition: "0.4s",
                }}
                {...register("city", {
                  required: `يجب اختيار المحافظة`,
                  validate: () =>
                    selectedCity != "اختر المحافظة" || "يجب اختيار المحافظة",
                })}
              >
                <option value="" disabled selected>
                  اختر المحافظة
                </option>
                {governorates?.map((i, index) => {
                  return (
                    <>
                      <option key={index} value={i.value}>
                        {i.label}
                      </option>
                    </>
                  );
                })}
              </select>
              {errors.city ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.city?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                // marginRight: "10px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: "40px",
                  fontSize: "15px",
                  color: "#000",
                  background: "#F5F5F5",
                  padding: "10px 20px",
                }}
              >
                البريد الالكتروني
              </span>
              <input
                type="email"
                placeholder="البريد الالكتروني"
                style={{
                  background: "#F5F5F5",
                  border: "1px solid #000",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: "black",
                  transition: "0.4s",
                }}
                {...register("email", {
                  required: `يجب ادخال البريد الالكتروني`,
                  pattern: {
                    value:
                      /^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*[a-zA-Z][a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/i,
                    message: "البريد الاكتروني غير صالح",
                  },
                })}
              />

              {errors.email ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.email?.message}
                </Typography>
              ) : (
                ""
              )}
              {error?.response?.data?.errors?.email?.[0] ===
                "The email has already been taken." ||
              error?.response?.status === 400 ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  هذا الايميل مستخدم من قبل
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                marginLeft: "10px",
                height: "100%",
              }}
            >
              <Box>
                <span
                  style={{
                    position: "absolute",
                    top: "-24px",
                    right: "40px",
                    fontSize: "15px",
                    color: "#000",
                    background: "#F5F5F5",
                    padding: "10px 20px",
                  }}
                >
                  كلمة السر
                </span>
                <input
                  type={passType}
                  placeholder="كلمة السر"
                  style={{
                    background: "#F5F5F5",
                    border: "1px solid #000",
                    padding: "20px 15px",
                    borderRadius: "5px",
                    outline: "none",
                    fontSize: "16px",
                    width: "100%",
                    color: "black",
                    transition: "0.4s",
                  }}
                  {...register("password", {
                    required: `يجب ادخال كلمة السر`,
                    minLength: {
                      value: 8,
                      message: "يجب ادخال علي الاقل 8 قيم",
                    },
                  })}
                />
                {passType === "password" ? (
                  <RemoveRedEyeOutlined
                    onClick={togglePassword}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "15px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      opacity: ".7",
                    }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    onClick={togglePassword}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "15px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      opacity: ".7",
                    }}
                  />
                )}
              </Box>

              {errors.password ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.password?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-24px",
                  right: "40px",
                  fontSize: "15px",
                  color: "#000",
                  background: "#F5F5F5",
                  padding: "10px 20px",
                }}
              >
                تأكيد كلمة السر
              </span>
              <input
                type={passType}
                placeholder="تأكيد كلمة السر"
                style={{
                  background: "#F5F5F5",
                  border: "1px solid #000",
                  padding: "20px 15px",
                  borderRadius: "5px",
                  outline: "none",
                  fontSize: "16px",
                  width: "100%",
                  color: "black",
                  transition: "0.4s",
                }}
                {...register("password_confirmation", {
                  required: `يجب ادخال تأكيد كلمة السر`,
                  minLength: {
                    value: 8,
                    message: "يجب ادخال علي الاقل 8 قيم",
                  },
                  validate: (value) =>
                    value === password || "يجب أن تكون كلمة السر متطابقة",
                })}
              />
              {errors.password_confirmation ? (
                <Typography
                  fontSize={15}
                  fontFamily={"Noor-bold"}
                  color={"red"}
                  mt={1}
                  mr={1}
                >
                  {errors.password_confirmation?.message}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="submit"
              onClick={""}
              sx={{
                background: "#000",
                width: "250px",
                padding: "10px 25px",
                color: "white",
                cursor: "pointer",
                transition: "0.4s",
                "&:hover": {
                  background: "#000",
                },
              }}
            >
              {isLoading ? <HashLoader color="#ffffff" size={24} /> : "انشاء"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}
AddStudent.propTypes = {
  openAddStudent: PropTypes.bool,
  closeAddStundet: PropTypes.func,
};
