import useCustomMutation from "@/hooks/useCustomMutation";
import { queryClient } from "@config/queryClient.config";
import { notifyError, notifySuccess } from "@shared/Toast/toast";

export default function useDeleteVideo({ id, onSettled }) {
  const { mutate: deleteVideoMutation, isPending: isLoading } =
    useCustomMutation({
      url: `lesson/${id}`,
      invalidate: ["lesson", id],
      method: "delete",
      onSettled,
      onSuccess: () => {
        notifySuccess("تم حذف الفيديو بنجاح");
        queryClient.invalidateQueries(["lesson", id]);
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    });

  function deleteVideo() {
    deleteVideoMutation();
  }

  return { deleteVideo, isLoading };
}
