import useCustomMutation from "@/hooks/useCustomMutation";
export default function useBanStudent({ onSettled }) {
  const { mutate, isPending } = useCustomMutation({
    url: "users/ban",
    invalidate: ["banStudent"],
    method: "post",
    successMsg: "تم حظر الطالب",
    onSettled,
  });

  function banStudent(data) {
    const formData = new FormData();
    Object.entries(data).forEach((key) => {
      formData.append(key[0], data[key[0]]);
    });
    mutate(formData);
  }

  return { banStudent, isPending };
}
