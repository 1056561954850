import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosConfig from "../Admin/Shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "../Admin/Shared/Toast/toast";

export default function useCustomMutation({
  url,
  method = "post",
  successMsg,
  invalidate,
  onSettled,
  onSuccess = () => {
    return;
  },
  ...options
}) {
  const queryClient = useQueryClient();
  const axios = useAxiosConfig();
  return useMutation({
    mutationFn: (data) => axios[method](url, method === "post" && data),
    ...options,
    onSuccess: (response) => {
      onSuccess(response);
      notifySuccess(successMsg);
      if (invalidate) {
        invalidate.forEach((element) => {
          queryClient.invalidateQueries({ queryKey: [element] });
        });
      }
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);

      // /// for testing only
      // /// temp solution
      // console.error("here");
      // if (!error?.response) {
      //   console.error("invalidating ...");

      //   invalidate.forEach((element) => {
      //     queryClient.invalidateQueries({ queryKey: [element] });
      //   });
      // }
    },
    onSettled,
  });
}
